module.exports={
  "name": "domain-story-modeler",
  "version": "1.1.1",
  "description": "A tool to visualize Domain Stories in the browser",
  "repository": {
    "type": "git",
    "url": "https://github.com/wps/domain-story-modeler.git"
  },
  "scripts": {
    "lint": "eslint .",
    "auto-test": "npm test -- --auto-watch --no-single-run",
    "test": "karma start",
    "bundle": "grunt build",
    "dev": "grunt auto-build",
    "all": "run-s lint test bundle"
  },
  "keywords": [
    "domain storytelling",
    "process modeling",
    "modeling",
    "domain-driven design",
    "ddd"
  ],
  "author": {
    "name": "WPS",
    "url": "https://wps.de"
  },
  "contributors": [
    {
      "name": "Domain Story Modeler contributors",
      "url": "https://github.com/wps/domain-story-modeler"
    }
  ],
  "license": "GPL-3.0-or-later",
  "devDependencies": {
    "@babel/core": "7.7.2",
    "@babel/preset-env": "7.7.1",
    "babelify": "^10.0.0",
    "browserify": "^16.5.0",
    "chai": "^4.2.0",
    "eslint": "^6.8.0",
    "eslint-plugin-bpmn-io": "^0.10.0",
    "grunt": "^1.1.0",
    "grunt-browserify": "^5.3.0",
    "grunt-cli": "^1.3.2",
    "grunt-contrib-clean": "^2.0.0",
    "grunt-contrib-connect": "^2.1.0",
    "grunt-contrib-copy": "^1.0.0",
    "grunt-contrib-watch": "^1.1.0",
    "grunt-copy-deps": "^1.4.0",
    "karma": "^4.4.1",
    "karma-browserify": "^6.1.0",
    "karma-chai": "^0.1.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-firefox-launcher": "^1.3.0",
    "karma-mocha": "^1.3.0",
    "karma-spec-reporter": "^0.0.32",
    "load-grunt-tasks": "^5.1.0",
    "mocha": "^6.2.2",
    "mocha-test-container-support": "^0.2.0",
    "npm-run-all": "^4.1.5",
    "puppeteer": "^2.1.1",
    "stringify": "^5.2.0",
    "time-grunt": "^2.0.0",
    "watchify": "^3.11.1"
  },
  "dependencies": {
    "bpmn-js": "^6.3.4",
    "diagram-js": "6.4.1",
    "diagram-js-direct-editing": "^1.6.1",
    "inherits": "2.0.4",
    "jquery": "^3.4.1",
    "min-dash": "^3.5.2",
    "sinon": "^7.5.0",
    "sortablejs": "^1.10.2",
    "tiny-svg": "^2.2.2",
    "vanilla-picker": "^2.10.1"
  },
  "browserify": {
    "transform": [
      [
        "babelify",
        {
          "presets": [
            "@babel/preset-env"
          ]
        }
      ]
    ]
  }
}
